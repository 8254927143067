@import "./custom.scss";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  max-height: 100%;
}

@font-face {
  font-family: 'poppins-light';
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'poppins-regular';
  src: url('./assets/fonts/poppins-regular.ttf');
}

@font-face {
  font-family: 'poppins-bold';
  src: url('./assets/fonts/poppins-bold.ttf');
}

.poppins-light {
  font-family: 'poppins-light' !important;
}

.poppins-regular {
  font-family: 'poppins-regular' !important;
}

.poppins-bold {
  font-family: 'poppins-bold' !important;
}

h1,
h2,
h3 {
  font-family: 'poppins-bold';
}
.custom-box-shadow{
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
p,
button,
h4,
h5,
h6 {
  font-family: 'poppins-regular';
}

h6 {
  font-size: 16px;
}

h5 {
  font-size: 18px;
}

h4 {
  font-size: 20px;
}

h3 {
  font-size: 22px;
}

h2 {
  font-size: 24px;
}

h1 {
  font-size: 26px;
}

p,
a,
button {
  font-size: 14px;
}

span {
  font-size: 12px;
}

.no-btn-structure {
  background-color: transparent;
  border: none;
  font-size: 18px;
}

.main-layout {
  display: flex !important;
}

.main-content {
  width: 100%;
}

/* pagination start */
.pagination-custom {
  list-style-type: none;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.pagination-custom a {
  text-decoration: none;
  color: map-get($theme-colors, "secondary") !important;
}

.pagination-custom li {
  padding: 2px 10px;
  border: 1px solid map-get($theme-colors, "secondary") !important;
}
.pagination-custom li.selected {
  padding: 2px 10px;
  background-color:map-get($theme-colors, "secondary") !important;
}
.pagination-custom li.selected a {
  color:map-get($theme-colors, "white") !important; ;
}


/* pagination end */

/* User Image Start */

.user-img {
  width: 50px;
  height: 50px;
  background-color: map-get($theme-colors, "primary") !important;
  border-radius: 50px;
  padding: 10px;
  color: map-get($theme-colors, "white") !important;
}

/* User Image end */

// tabs scss start

.user-profile-nav-tabs .nav-tabs {
  border-bottom: 0px solid map-get($theme-colors, "white") !important;
}

.user-profile-nav-tabs .nav-link.active {
  background-color: map-get($theme-colors, "primary") !important;
  color: map-get($theme-colors, "white") !important;
  border: 1px solid map-get($theme-colors, "secondary") !important;
  color: map-get($theme-colors, "white") !important;
}

.user-profile-nav-tabs .nav-link {
  border: 1px solid map-get($theme-colors, "secondary") !important;
  color: map-get($theme-colors, "secondary") !important;
  margin-right: 10px;
}

// tabs scss end

// card css start
.card-inner {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 5px;
  padding: 25px 25px;
  margin-bottom: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;


  $angle: 245deg;
  $position1: 48%;
  $position2: 10%;

  background: linear-gradient(#{$angle},
    map-get($theme-colors, "primary") $position1,
    map-get($theme-colors, "secondary") $position2 );


}


// card css  end

